.menu-wrap {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
}

.nav-menu {
  display: none;
  justify-content: center;
  align-items: center;
}

.nav-link {
  font-size: 1.6rem;
  font-weight: 400;
  color: white;
  transition: all 0.5s;
  text-decoration: none;
}

.nav-link:hover {
  color: rgb(196, 125, 79);
}

@media only screen and (max-width: 760px) {
  .nav-menu {
    position: fixed;
    left: -200%;
    display: flex;
    flex-direction: column;
    background-color: rgba(101, 127, 150, 0.65);
    width: 100%;
    height: 100vh;
    text-align: center;
    transition: 0.3s;
    box-shadow: 0 10px 27px rgba(0, 0, 0, 0.05);
  }

  .nav-menu.active {
    left: 0;
  }

  .nav-item {
    margin: 1.5rem 0;
  }
}
